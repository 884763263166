<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcGideonCardVideoShowings }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load()"
        :tooltip="translations.tcTooltip"
        :i18n="translations.components"
      ></camp-selector>
      <!-- / Camp Selector -->
      <data-table
        :fields="dataTableFields"
        :listType="list_type"
        :items="videoShowings"
        :toggleMe="true"
        :hiddenItems="hiddenItems"
        :addItems="addItems"
        :searchTerm="`visitsTerm`"
        :csvUrl="csvUrl"
        :pdfUrl="pdfUrl"
        :reportName="reportName"
        @addPresentationClick="handleAddPresentationClick"
        :includeAddButton="includeAddButton"
        :i18n="translations.components"
      ></data-table>
      <!-- / Data Tables -->

      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'video-showings',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        add_gideoncard_video_presentation_button: 'ed32320b-3333-444d-9f80-038c2808c769',
      },
      list_type: 'video_showing',
      dataTableFields: [
        {
          key: 'activity_date',
          label: '',
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
          sortable: true,
        },
        { key: 'facility_name', label: '', sortable: true },
        { key: 'city', label: '', sortable: false },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Video Presentation',
        action: 'addPresentationClick',
      },
      includeAddButton: true,
      reportName: 'GideonCardVideoShowings',
    }
  },
  methods: {
    ...mapActions({
      clearFacilityDetails: 'card/clearFacilityDetails',
      clearVideoVisitDetails: 'card/clearVideoVisitDetails',
      getVideoCSVUrl: 'card/getVideoCSVUrl',
      getVideoShowings: 'card/getVideoShowings',
      getVideoShowingsPDFUrl: 'card/getVideoShowingsPDFUrl',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        let videoData = await this.getFutureVideoDate()
        if (this.getVideoShowings.length === 0) {
          await Promise.all([this.getVideoShowings(videoData)])
        }
        await this.getVideoCSVUrl(videoData)
        await this.getVideoShowingsPDFUrl(videoData)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    getFutureVideoDate() {
      let startDate = new Date()
      let startMonth = startDate.getMonth() + 1
      let startDateInString =
        startDate.getFullYear() + '-' + (startMonth < 10 ? '0' + startMonth : startMonth) + '-' + startDate.getDate()
      let endDate = new Date()
      endDate.setMonth(endDate.getMonth() + 36)
      let endMonth = endDate.getMonth() + 1
      let endDateInString =
        endDate.getFullYear() + '-' + (endMonth < 10 ? '0' + endMonth : endMonth) + '-' + endDate.getDate()
      let data = {
        startDate: startDateInString,
        endDate: endDateInString,
      }
      return data
    },
    addPresenter() {
      this.$router.push('/')
    },
    async handleAddPresentationClick() {
      this.setSelectedFacilityKey(null)
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearVideoVisitDetails()
      this.$router.push('/programs/gc/events/card_video_presentation_add')
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddVideoPresentation
      this.addItems.display = this.iCanSee(this.secured_controls.add_gideoncard_video_presentation_button)
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        {
          key: 'activity_date',
          label: this.translations.tcActivityDate,
          formatter: (value, key, item) => {
            return value.slice(0, 10)
          },
          sortable: true,
        },
        { key: 'facility_name', label: this.translations.tcFacilityName, sortable: true },
        { key: 'city', label: this.translations.tcCity, sortable: false },
      ]
    },
  },
  async created() {
    Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('data-table', 'camp-select', 'security-banner'),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.loadTranslations()
    })
  },
  computed: {
    ...mapGetters({
      csvUrl: 'card/videoCSVUrl',
      pdfUrl: 'card/videoShowingsPDFUrl',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userOrgKey: 'user/userOrgKey',
      videoShowings: 'card/videoShowings',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
